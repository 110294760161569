/** @define header; weak */
@media only screen and (max-width: 799px) {
  .header {
    background: rgba(0, 0, 0, 0.5);
  }
}
.header__menu-fixed {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99;
  background-color: #000000;
}
.header__logo a {
  display: block;
  line-height: 1;
  vertical-align: middle;
}
.header__logo .thule {
  margin-left: 10px;
}
.header--grid {
  position: relative;
  display: grid;
}
.header--grid .header__contact--break {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header--grid .header__contact--break .text {
  display: none;
}
@media only screen and (min-width: 400px) {
  .header--grid .header__contact--break .text {
    display: inherit;
  }
}
@media only screen and (max-width: 799px) {
  .header--grid {
    grid-template-areas: "contact contact" "logo menu";
    grid-template-rows: 50px 1fr;
    grid-template-columns: 300px 1fr;
  }
  .header--grid .header__logo {
    grid-area: logo;
    align-self: center;
    margin-left: 10px;
    padding: 5px;
  }
  .header--grid .header__contact {
    grid-area: contact;
    align-self: start;
    padding: 10px;
    text-align: right;
  }
  .header--grid .header__menu {
    grid-area: menu;
    justify-self: end;
    max-width: 100%;
    color: #ffffff;
    font-family: montserratsemibold, arial, Helvetica, sans-serif;
  }
  .header--grid .header__menu .wsdownopener {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 10px;
    cursor: pointer;
  }
  .header--grid .header__menu svg {
    width: 40px;
    height: 24px;
    color: #f59a00;
  }
}
@media only screen and (max-width: 400px) {
  .header--grid {
    grid-template-areas: "contact contact" "logo menu";
    grid-template-rows: 50px 1fr;
    grid-template-columns: 250px 1fr;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1039px) {
  .header--grid {
    grid-template-areas: "logo contact menu";
    grid-template-rows: 1fr;
    grid-template-columns: 300px 1fr 100px;
  }
  .header--grid .header__logo {
    grid-area: logo;
    align-self: center;
    margin-left: 10px;
    padding: 5px;
  }
  .header--grid .header__contact {
    grid-area: contact;
    align-self: center;
    padding: 10px;
    text-align: right;
  }
  .header--grid .header__menu {
    grid-area: menu;
    justify-self: end;
    max-width: 100%;
    color: #ffffff;
    font-family: montserratsemibold, arial, Helvetica, sans-serif;
  }
  .header--grid .header__menu .wsdownopener {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 10px;
    cursor: pointer;
  }
  .header--grid .header__menu svg {
    width: 40px;
    height: 24px;
    color: #f59a00;
  }
}
@media only screen and (min-width: 1040px) {
  .header--grid {
    grid-template-areas: "contact contact" "logo menu";
    grid-template-rows: 50px 1fr;
    grid-template-columns: 350px 1fr;
  }
  .header--grid .header__logo {
    grid-area: logo;
    align-self: center;
    margin-left: 10px;
    padding: 5px;
  }
  .header--grid .header__contact {
    grid-area: contact;
    align-self: start;
    padding: 10px;
    text-align: right;
  }
  .header--grid .header__menu {
    grid-area: menu;
    justify-self: end;
    max-width: 100%;
  }
}
@media only screen and (min-width: 1450px) {
  .header--grid {
    grid-template-areas: "logo menu contact";
    grid-template-rows: 1fr;
    grid-template-columns: 380px 1fr 22%;
  }
  .header--grid .header__logo {
    grid-area: logo;
    align-self: center;
    margin-left: 20px;
    padding: 10px;
  }
  .header--grid .header__contact {
    grid-area: contact;
    align-self: center;
    margin-right: 20px;
    padding-right: 10px;
    text-align: right;
  }
  .header--grid .header__contact--break .header__contact--break {
    display: block;
  }
  .header--grid .header__menu {
    grid-area: menu;
  }
}
.header__contact--break .header__contact--break {
  padding-left: 10px;
  color: #ffffff;
  border-left: 1px solid #f59a00;
}
@media only screen and (min-width: 400px) {
  .header__contact--break .header__contact--break {
    padding-left: 20px;
  }
}
.header__contact--break a {
  color: #ffffff;
}
.header__contact--social {
  margin-right: 10px;
}
@media only screen and (min-width: 400px) {
  .header__contact--social {
    margin-right: 20px;
  }
}
.header__contact--social .link {
  margin: 0 5px;
}
.header__contact--social svg {
  width: 24px;
  height: 24px;
  color: #f59a00;
}
.header__contact--social span {
  display: none;
}
/** @define header-icon; weak */
.header-icon {
  color: #bebebe;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out, border 0.4s ease-in-out;
}
@media only screen and (max-width: 1200px) {
  .header-icon {
    flex-grow: 1;
  }
}
@media only screen and (min-width: 1201px) {
  .header-icon {
    padding: 0 15px;
  }
}
.header-icon:hover {
  color: #000000;
  text-decoration: none;
  background-color: #ddd;
}
.header-icon:hover .basket_counter_number {
  color: #ffffff;
  background-color: #be2929;
}
.header-icon:last-child {
  color: #ffffff;
  background-color: #be2929;
  border: 1px solid #be2929;
}
.header-icon:last-child:hover {
  color: #000000;
  background-color: #ddd;
  border: 1px solid #ddd;
}
.header-icon__svg {
  position: relative;
  width: 100%;
  padding: 5px 10px 3px 10px;
  /*
  .icon_archive {
    width: 30px;
    height: 32px;
  }

  .icon_sliders {
    width: 30px;
    height: 32px;
  }

  .icon_search {
    width: 30px;
    height: 32px;
  }

  .icon_user {
    width: 30px;
    height: 32px;
  }

  */
}
.header-icon__svg .icon_svg {
  width: 30px;
  height: 32px;
}
.header-icon__svg .icon_basket {
  width: 40px;
  height: 32px;
}
.header-icon__svg .basket_counter_number {
  position: absolute;
  top: 8px;
  left: 50%;
  display: table-cell;
  margin-top: -3px;
  margin-left: 5px;
  padding: 5px 8px;
  color: #000000;
  font-size: 12px;
  line-height: 1;
  font-family: montserratsemibold, arial, Helvetica, sans-serif;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #be2929;
  border-radius: 50px;
  transition: all 0.4s ease-in-out;
}
.header-icon__text {
  flex-basis: 24px;
  width: 100%;
  padding: 0 3px 12px 3px;
  font-size: 14px;
}
/*# sourceMappingURL=css/header.css.map */